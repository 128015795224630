<template>
  <v-tooltip top>
    <template v-slot:activator="{on}">
      <v-btn
        v-on="on"
        icon
        @click="copy"
        :disabled="!hasPassword"
      >
        <v-icon>
          fa fa-copy
        </v-icon>
      </v-btn>
    </template>

    <span>{{'page.action.copy_secret_link'| t}}</span>
  </v-tooltip>
</template>

<script lang="babel" type="text/babel">
export default {
  props: {
    formKey: String,
  },
  methods: {
    async copy() {
      try {
        await this.$copyText(this.secretLink)
      } catch (error) {
        console.error(error)
      }
      this.$snotify.success(this.secretLink, this.$t('copy.successfully'))
    },
  },
  computed: {
    secretLink() {
      if(!this.formData) return null
      return `${this.frontendDomain}/secret-page/${this.formData.id}?p=${this.password}`
    },
    frontendDomain() {
      return this.$store.getters['base/application'].frontendDomain
    },
    hasPassword() {
      return this.$helper.textEmpty(this.password) == false
    },
    password() {
      return window.eagleLodash.get(this.formData, ['p'])
    },
    formData() {
      return this.$store.getters[`form/${this.formKey}/data`]
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
